
  import Testimonials from "@/components/Testimonials.vue";
  import { Vue, Component } from "vue-property-decorator";

  @Component({
    components: {
      Testimonials,
    },
  })
  export default class Home extends Vue {}
